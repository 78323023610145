import { addAlphaToHexColor } from '../utils/helpers';
import { Theme } from '@mui/material/styles';
import { customBreakpoints, customPalette } from '../themes/custom';

const shadows: Array<string> = [];
for (let i = 0; i < 25; i += 1) {
  shadows.push(`0 1px 1px 1px rgba(0, 0, 0, 0.${i})`);
}

const baseFontSize = 14;
const baseFontSizeSmall = 12;
export const degularFontFamily = 'degular, sans-serif';

const typography = {
  fontFamily: degularFontFamily,
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  fontSize: baseFontSize,
  fontSizeSmall: baseFontSizeSmall,
  htmlFontSize: baseFontSize,
  h1: {
    fontSize: '96px',
    fontWeight: 300,
    lineHeight: 1.167,
    letterSpacing: '-1.5px',
    color: customPalette.colors.cnftDarkGrey,
  },
  h2: {
    fontSize: '60px',
    fontWeight: 300,
    lineHeight: 1.2,
    letterSpacing: '-0.5px',
    color: customPalette.colors.cnftDarkGrey,
  },
  h3: {
    fontSize: '48px',
    fontWeight: 400,
    lineHeight: 1.167,
    letterSpacing: '0px',
    color: customPalette.colors.cnftDarkGrey,
  },
  h4: {
    fontSize: '34px',
    fontWeight: 400,
    lineHeight: 1.235,
    letterSpacing: '0.25px',
    color: customPalette.colors.cnftDarkGrey,
  },
  h5: {
    fontSize: '24px',
    fontWeight: 600,
    lineHeight: 1.334,
    letterSpacing: '0px',
    color: customPalette.colors.cnftDarkGrey,
  },
  h6: {
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: 1.6,
    letterSpacing: '0.15px',
    color: customPalette.colors.cnftDarkGrey,
  },
  subtitle: {
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: 1.5,
    letterSpacing: '0.15px',
  },
  body1: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: 1.5,
    letterSpacing: '0.15px',
  },
  body2: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: 1.43,
    letterSpacing: '0.17px',
  },
  tooltip: {
    fontSize: '10px',
    fontWeight: 500,
    lineHeight: 1.4,
  },
  buttonLarge: {
    fontSize: '15px',
    fontWeight: 500,
    lineHeight: 1.73,
    letterSpacing: '0.46px',
    textTransform: 'uppercase',
  },
  buttonMedium: {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: 1.71,
    letterSpacing: '0.4px',
    textTransform: 'uppercase',
  },
  buttonSmall: {
    fontSize: '13px',
    fontWeight: 500,
    lineHeight: 1.69,
    letterSpacing: '0.46px',
    textTransform: 'uppercase',
  },
  caption: {
    color: addAlphaToHexColor('#000000', 60),
    fontSize: 12,
  },
};

const common = {
  white: customPalette.colors.white,
  black: customPalette.colors.black,
};

const palette = {
  mode: 'light',
  primary: {
    main: customPalette.colors.cnftDarkGrey,
    light: customPalette.colors.cnftDarkGreyLight,
    lighter: customPalette.colors.cnftDarkGreyLighter,
    dark: customPalette.colors.cnftDarkGrey,
    darker: customPalette.colors.cnftDarkGreyDark,
    contrastText: customPalette.colors.white,
  },
  secondary: {
    main: customPalette.colors.cnftDarkGrey,
    lighter: customPalette.colors.cnftDarkGreyLighter,
    light: customPalette.colors.cnftDarkGreyLight,
    dark: customPalette.colors.cnftDarkGreyDark,
    darker: customPalette.colors.cnftDarkGreyDark,
    contrastText: customPalette.colors.white,
  },
  background: {
    paper: customPalette.colors.white,
    page: customPalette.colors.white,
  },
  text: {
    primary: customPalette.colors.primaryText,
    secondary: customPalette.colors.secondaryText,
    disabled: customPalette.colors.disabledText,
    primaryChannel: '0 0 0',
    secondaryChannel: '0 0 0',
  },
  info: {
    main: customPalette.colors.cnftDarkGrey,
    light: customPalette.colors.cnftDarkGreyLight,
    lighter: customPalette.colors.cnftDarkGreyLighter,
    dark: customPalette.colors.cnftDarkGrey,
    darker: customPalette.colors.cnftDarkGreyDark,
    contrastText: customPalette.colors.white,
  },
  warning: {
    main: customPalette.colors.warning,
    light: customPalette.colors.warning,
    lighter: customPalette.colors.warning,
    dark: customPalette.colors.warning,
    darker: customPalette.colors.warning,
    contrastText: customPalette.colors.white,
  },
  success: {
    main: customPalette.colors.success,
    light: customPalette.colors.success,
    lighter: customPalette.colors.success,
    dark: customPalette.colors.success,
    darker: customPalette.colors.success,
    contrastText: customPalette.colors.white,
  },
  error: {
    main: customPalette.colors.error,
    light: customPalette.colors.error,
    lighter: customPalette.colors.error,
    dark: customPalette.colors.error,
    darker: customPalette.colors.error,
    contrastText: customPalette.colors.white,
  },
  colors: customPalette.colors,
  gradients: customPalette.gradients,
  common,
};

export const theme: Theme = {
  palette,
  typography,
  shadows,
  breakpoints: customBreakpoints,
  components: {
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          color: palette.primary.main,
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
        InputLabelProps: {
          shrink: true,
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: 'outlined',
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: customPalette.colors.cnftDarkGrey,
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          '.MuiListItemButton-root': {
            color: palette.colors.cnftDarkGrey,
            fill: palette.colors.cnftDarkGrey,
            background: palette.colors.cnftDarkGreyLighter,
            fontSize: '1.85rem',
            minHeight: 48,
            justifyContent: 'initial',
            px: 2.5,
            borderBottomWidth: '1px',
            borderBottomStyle: 'solid',
            borderBottomColor: 'colors.lightGrey',
            fontWeight: 600,
            '&:last-child': {
              borderBottom: '1px solid lightgrey',
            },
            '&:hover': {
              background: palette.colors.cnftHover,
              fill: palette.colors.cnftHover,
            },
            '&.Mui-selected': {
              background: palette.colors.white,
              '.MuiSvgIcon-root': {
                color: palette.colors.cnftDarkGrey,
                fill: palette.colors.cnftDarkGrey,
              },
              '.MuiTypography-root': {
                color: palette.colors.cnftDarkGrey,
                fontWeight: 700,
              },
            },
            '&:not(.Mui-selected)': {
              '.MuiSvgIcon-root': {
                color: palette.colors.cnftDarkGrey,
                fill: palette.colors.cnftDarkGrey,
              },
            },
          },
          '.MuiListItemIcon-root': {
            minWidth: 0,
            marginRight: '16px',
          },
        },
      },
    },
    MuiAlert: {
      defaultProps: {
        variant: 'outlined',
      },
      styleOverrides: {
        root: {
          padding: '0px 16px',
        },
      },
    },
    MuiChip: {
      defaultProps: {
        variant: 'outlined',
        size: 'small',
        color: 'secondary',
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          background: palette.colors.cnftPageBackground,
          border: `1px solid ${palette.colors.lightGrey}`,
          boxShadow: '0 0 0 0 black',
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        color: 'primary',
      },
      styleOverrides: {
        root: {
          'input:read-only': {
            cursor: 'default',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 30,
          fontWeight: 600,
          '&:hover': {
            background: palette.colors.cnftHover,
            color: palette.colors.cnftDarkGrey,
            fontWeight: 600,
          },
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiModal: {
      styleOverrides: {
        root: {
          '.MuiButton-root': {
            borderRadius: 30,
            fontWeight: 600,
            '&.MuiButton-outlinedPrimary': {
              '&:hover': {
                fontWeight: 600,
              },
            },
          },
        },
      },
    },
    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          ':not(.Mui-Selected):hover': {
            '.MuiTypography-root, .MuiSvgIcon-root': {
              color: palette.colors.cnftDarkGrey,
            },
          },
          '&:hover': {
            background: palette.colors.cnftHover,
            fill: palette.colors.cnftHover,
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          '.MuiButtonBase-root': {
            textTransform: 'none',
            fontWeight: 600,
          },

          '.Mui-selected': {
            '.MuiTypography-root, .MuiSvgIcon-root': {
              color: palette.colors.cnftDarkGrey,
              fontWeight: 700,
            },
          },

          '.MuiTabs-indicator': {
            backgroundColor: palette.colors.cnftDarkGrey,
          },
        },
      },
    },

    MuiSvgIcon: {
      defaultProps: {
        fontSize: 'small',
      },
    },

    MuiDataGrid: {
      defaultProps: {
        density: 'standard',
      },

      styleOverrides: {
        root: {
          border: 0,
          '.MuiDataGrid-columnHeaders': {
            borderBottom: `1px solid ${palette.primary.main}`,

            '.MuiSvgIcon-root': {
              color: palette.primary.main,
              fill: palette.primary.main,
            },
          },
          '.MuiDataGrid-columnSeparator': {
            opacity: '0 !important',
          },

          '.MuiDataGrid-cell:focus': {
            outline: 'none !important',
          },

          '.MuiDataGrid-cell:focus-within, .MuiDataGrid-columnHeader:focus-within': {
            outline: 'none !important',
          },

          '.MuiDataGrid-virtualScrollerContent': {
            background: 'transparent',
          },

          '.MuiDataGrid-footerContainer': {
            borderTop: 0,
          },

          '.MuiDataGrid-virtualScrollerRenderZone': {
            background: common.white,
          },

          '.MuiDataGrid-cell .MuiButtonBase-root': {
            paddingTop: '2.5px',
            paddingBottom: '2.5px',
            marginLeft: 4,
          },

          '.MuiDataGrid-toolbarContainer': {
            padding: '10px 5px',
            borderBottom: `1px solid ${palette.colors.lightGrey}`,
          },

          '.MuiDataGrid-row': {
            '&:hover Button': {
              background: palette.colors.cnftHover,
              fontWeight: 600,
            },
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        '&::-webkit-scrollbar': {
          width: '6px',
          height: '6px',
        },

        '&::-webkit-scrollbar-track': {
          borderRadius: '30px',
          background: customPalette.colors.lightGrey,
        },

        '&::-webkit-scrollbar-thumb': {
          borderRadius: '30px',
          background: palette.primary.light,
        },

        '&::-webkit-scrollbar-thumb:hover': {
          background: palette.primary.main,
        },

        '&::-webkit-scrollbar-thumb:active': {
          background: palette.primary.main,
        },

        body: {
          backgroundColor: 'transparent',
          fontSize: `${baseFontSize}px`,
          boxSizing: 'border-box',

          '.notistack-MuiContent-success': {
            backgroundColor: `${palette.success.main} !important`,
            color: `${palette.success.contrastText} !important`,
          },

          '.notistack-MuiContent-info': {
            backgroundColor: `${palette.primary.main} !important`,
            color: `${palette.primary.contrastText} !important`,
          },

          '.notistack-MuiContent-warning': {
            backgroundColor: `${palette.warning.main} !important`,
            color: `${palette.warning.contrastText} !important`,
          },

          '.notistack-MuiContent-error': {
            backgroundColor: `${palette.error.main} !important`,
            color: `${palette.error.contrastText} !important`,
          },
          '.MuiGrid-row-odd': {
            backgroundColor: palette.colors.table.odd,
          },
          '.MuiGrid-row-even': {
            backgroundColor: palette.colors.table.even,
          },
        },
        html: {
          fontSize: `${baseFontSize}px`,
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          '.MuiSwitch-track': {
            backgroundColor: 'white',
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          paddingLeft: 0,
          paddingRight: 0,
          '&.MuiTableCell-body': {
            paddingLeft: 0,
            paddingRight: 0,
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          '&.MuiDataGrid-filterFormColumnInput': {
            paddingRight: 8,
          },
          '&.MuiDataGrid-filterFormOperatorInput': {
            paddingRight: 8,
          },
          '&.MuiDataGrid-filterFormValueInput': {
            paddingRight: 0,
          },
        },
      },
      MuiBox: {
        styleOverrides: {
          root: {
            borderBottom: 'unset',
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: `${customPalette.colors.error}`,
          border: `1px solid ${palette.colors.lightGrey}`,
          padding: 4,
          borderRadius: 5,
          fontWeight: 500,
        },
      },
    },
  },
} as any;

export default theme;
