import { useMemo, useState } from 'react';
import styled from '@emotion/styled';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Icon } from 'atoms';
import { palette } from '@mui/system';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import { degularFontFamily } from 'common/themes/default';

interface PalleteSystemProps {
  color?: string;
  bgcolor?: string;
}

export type EthAddressProps = PalleteSystemProps & {
  address: string;
  networkId: number;
  full?: boolean;
  charLengthOnEitherSide?: number;
  showIcon?: boolean;
  iconSize?: number;
  iconColor?: string;
  showBlockExplorer?: boolean;
};

export const AddressDisplay = styled(Box)<EthAddressProps>`
  color: ${props => (props.color ? props.color : props.theme.palette.primary.main)};
  cursor: pointer;
  font-family: '${degularFontFamily}';
  display: flex;
  align-items: center;
  &:hover{
    font-weight: bold;
  }
  ${palette};
`;

export const AddressValue = styled(Box)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex-shrink: 1;
`;

const networkNames: { [key: number]: string } = {
  1: 'Ethereum',
  10: 'Optimism',
  137: 'Polygon',
  8453: 'Base',
  42161: 'Arbitrum',
  59144: 'Linea',
  80002: 'Polygon Amoy',
  11155111: 'Sepolia',
};

export const supportedNetworks = Object.keys(networkNames).map(Number);

export const networkName = (networkId: number): string => {
  return networkNames[networkId] || `Network ${networkId} disabled`;
};

export const supportedNetworkDetails = supportedNetworks.map(networkId => ({
  id: networkId,
  name: networkName(networkId),
}));

const EthAddressExplorer = ({
  address = '',
  networkId = 1,
  showIcon = false,
  iconSize = 22,
  iconColor = 'black',
  full = false,
  charLengthOnEitherSide = 4,
  showBlockExplorer = true,
  ...rest
}) => {
  const displayedAddress = useMemo(() => {
    if (full || !address) return address;
    return `${address.slice(0, 2 + charLengthOnEitherSide)}...${address.slice(
      0 - charLengthOnEitherSide
    )}`;
  }, [address, full, charLengthOnEitherSide]);

  const [copied, setCopied] = useState(false);

  const openExplorer = () => {
    let url = '';
    switch (networkId) {
      default:
        url = `https://etherscan.io/address/${address}`;
        break;
      case 10:
        url = `https://optimistic.etherscan.io/address/${address}`;
        break;
      case 137:
        url = `https://polygonscan.com/address/${address}`;
        break;
      case 8453:
        url = `https://basescan.org//address/${address}`;
        break;
      case 42161:
        url = `hhttps://arbiscan.io/address/${address}`;
        break;
      case 59144:
        url = `https://lineascan.build/address/${address}`;
        break;
      case 80002:
        url = `https://amoy.polygonscan.com/address/${address}`;
        break;
      case 11155111:
        url = `https://sepolia.etherscan.io/address/${address}`;
        break;
    }
    if (url) {
      window.open(url);
    }
  };

  return (
    <AddressDisplay address={address} networkId={networkId}>
      <Tooltip title={copied ? 'Copied!' : 'Copy to clipboard'}>
        <Box display="flex" alignItems="center">
          <CopyToClipboard text={address} onCopy={() => setCopied(true)}>
            <AddressDisplay
              address={address}
              networkId={networkId}
              {...rest}
              onMouseLeave={() => setCopied(false)}
            >
              <AddressValue>{displayedAddress}</AddressValue>
              {showIcon && (
                <Icon
                  name="copy"
                  size={`${iconSize}`}
                  display="inline-block"
                  height={`${iconSize}px`}
                  ml="8px"
                  color={iconColor}
                />
              )}
            </AddressDisplay>
          </CopyToClipboard>
        </Box>
      </Tooltip>

      {showBlockExplorer && supportedNetworks.includes(networkId) && (
        <Tooltip title={'View in Explorer'}>
          <ExitToAppOutlinedIcon fontSize="small" onClick={openExplorer} />
        </Tooltip>
      )}
    </AddressDisplay>
  );
};

export default EthAddressExplorer;
