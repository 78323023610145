import React, { useState } from 'react';
import styled from '@emotion/styled';
import Tooltip from '@mui/material/Tooltip';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Flex from './Flex';
import Icon from './Icon';
import { Box, palette } from '@mui/system';
import { degularFontFamily } from 'common/themes/default';

interface PalleteSystemProps {
  color?: string;
  bgcolor?: string;
}

export type EthAddressProps = PalleteSystemProps & {
  address: string;
  full?: boolean;
  isTxHash?: boolean;
  charLengthOnEitherSide: number;
  showIcon: boolean;
  iconSize: number;
  iconColor: string;
};

const AddressDisplay = styled(Flex)<any>`
  color: ${props =>
    props.color
      ? props.color
      : props.isTxHash
      ? props.theme.palette.grey[500]
      : props.theme.palette.primary.main};
  cursor: pointer;
  font-family: '${degularFontFamily}';
  &:hover{
    font-weight: bold;
  }
  ${palette};
`;

const AddressValue = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex-shrink: 1;
`;

const EthAddress = ({
  address = '',
  showIcon = false,
  iconSize = 22,
  iconColor = 'primary.contrastText',
  full = false,
  charLengthOnEitherSide = 4,
  ...rest
}) => {
  const displayedAddress = React.useMemo(() => {
    if (full || !address) return address;
    return `${address.slice(0, 2 + charLengthOnEitherSide)}...${address.slice(
      0 - charLengthOnEitherSide
    )}`;
  }, [address, full, charLengthOnEitherSide]);

  const [copied, setCopied] = useState(false);

  return (
    <Tooltip title={copied ? 'Copied!' : 'Copy to clipboard'}>
      <Box display="flex" alignItems="center">
        <CopyToClipboard text={address} onCopy={() => setCopied(true)}>
          <AddressDisplay {...rest} onMouseLeave={() => setCopied(false)} alignCenter>
            <AddressValue>{displayedAddress}</AddressValue>
            {showIcon && (
              <Icon
                name="copy"
                size={`${iconSize}`}
                display="inline-block"
                height={`${iconSize}px`}
                ml="8px"
                color={iconColor}
              />
            )}
          </AddressDisplay>
        </CopyToClipboard>
      </Box>
    </Tooltip>
  );
};

export default EthAddress;
