import React from 'react';
import styled from '@emotion/styled';
import { string } from 'prop-types';
import { useTheme } from '@mui/styles';
import { color, display, spacing, typography } from '@mui/system';

const Link = styled.a`
  display: block;
  color: ${props => props.theme.palette.primary};
  margin: 16px 0;

  ${typography};
  ${spacing};
  ${color};
  ${display};
`;

const ExternalLink = ({ href, text, ...rest }) => (
  <Link href={href} target="_blank" rel="noopener noreferrer" theme={useTheme()} {...rest}>
    {text}
  </Link>
);

ExternalLink.propTypes = {
  href: string,
  text: string,
};

export default ExternalLink;
