import { createRoot } from 'react-dom/client';
import App from 'common/components/App';
import 'index.css';

document.addEventListener('DOMContentLoaded', () => {
  const container = document.getElementById('root');
  if (container) {
    const root = createRoot(container);
    root.render(<App />);
  } else {
    console.error('Root container not found');
  }
});
