import React, { Suspense } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { GlobalSnackbar } from 'atoms';
import AppProviders from './AppProviders';
import { Auth0Provider } from '@auth0/auth0-react';
import { lazyLoadFeedbackMinimumDelay, authDomain, authClientId, audience } from 'config';
import RouteLoaderPage from './RouteLoaderPage';

const importWithMinimumDelay = (importFn: any) => {
  return React.lazy(async () => {
    return await Promise.all([
      importFn(),
      new Promise(resolve => setTimeout(resolve, lazyLoadFeedbackMinimumDelay)),
    ]).then(([moduleExports]) => moduleExports);
  });
};

const LazyAuth = importWithMinimumDelay(() => import('features/auth'));
const LazyDashboard = importWithMinimumDelay(() => import('features/dashboard'));

const App = () => {
  return (
    <AppProviders>
      <Suspense fallback={<RouteLoaderPage />}>
        <Router basename={process.env.PUBLIC_URL}>
          <Auth0Provider
            domain={authDomain}
            clientId={authClientId}
            audience={audience}
            redirectUri={`${window.location.origin}/auth/login`}
          >
            <Switch>
              <Route key="auth" path="/auth" component={LazyAuth} />
              <Route key="dashboard" path="/" component={LazyDashboard} />
            </Switch>
          </Auth0Provider>
        </Router>
        <GlobalSnackbar />
      </Suspense>
    </AppProviders>
  );
};

export default App;
