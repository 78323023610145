import styled from '@emotion/styled';
import { palette, sizing } from '@mui/system';
import { getStatusColor } from 'common/themes/utils';

const Label = styled.label`
  display: inline-block;
  color: ${props => getStatusColor(props, 'black')};
  font-size: ${props => props.theme.typography.fontSizeSmall}px;
  font-weight: 500;
  line-height: 22px;
  margin-bottom: 4px;
  ${palette};
  ${sizing}
`;

export default Label;
