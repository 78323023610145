/* eslint-disable camelcase */

import { BigNumberish, formatUnits } from 'ethers';

const MS_PER_DAY = 1000 * 60 * 60 * 24;
export const DATE_FULL_FORMAT = 'D MMMM, YYYY';
export const DATE_FORMAT = 'MM/D/YY';

export const isCurrentURL = url => window.location.pathname === url;

export function getCurrentYear() {
  return new Date().getFullYear();
}

export const formatAuthHeader = (authType: string, accessToken: string) => {
  return `${authType} ${accessToken}`;
};

export const formatError = error => {
  console.error(error);

  if (typeof error === 'string') {
    return error;
  }

  if (error?.response?.data) {
    const { data } = error.response;

    if (data.message) {
      return data.message;
    }

    if (data.errors?.length) {
      return data.errors[0].detail;
    }

    if (data.error?.detail) {
      return data.error.detail;
    }
  }

  if (error?.message) {
    return error.message;
  }

  return 'Something went wrong. Please try again.';
};

export const removeTrailingSlash = url => {
  return url.replace(/\/$/, '');
};

const quotaError = errorObject => errorObject?.request?.status === 402;

export const apiErrorMessageResolver = (primaryMessage, errorObject) => {
  if (quotaError(errorObject)) {
    return `You have reached your plan's limit. Please contact support.`;
  }
  const reasons: string[] = [];
  try {
    errorObject.response.data.errors.forEach(({ code, detail }) => {
      if (code === 'IntegrityError') {
        if (detail.indexOf('ingredient_supplier_id') !== -1) {
          reasons.push('the ingredient is being used in a supplier request');
        } else if (detail.indexOf('certificate_request_claim_id_fkey') !== -1) {
          reasons.push('the claim is being used in a certificate request');
        } else if (detail.includes('ingredient_parent_product_id_name_key')) {
          reasons.push('the ingredient already exists');
        }
      }
    });
  } catch (ex) {
    console.error(ex);
  }
  const reason = reasons.length ? ` because ${reasons.join(' and ')}` : '';
  if (primaryMessage) {
    return primaryMessage;
  }
  if (reason) {
    return `Something went wrong${reason}. Please try again.`;
  }
  return formatError(errorObject);
};

const FORMAT_CURRENCY_SYMBOLS = {
  USD: '$',
  ETH: 'Ξ',
};

const FORMAT_CURRENCY_DECIMALS = {
  USD: 2,
  ETH: 5,
};

export const formatCurrency = (value, currency, { symbol = true, lang = 'en-US' } = {}) => {
  const formattedSymbol = symbol ? FORMAT_CURRENCY_SYMBOLS[currency] : '';
  const formattedValue = new Intl.NumberFormat(lang, {
    minimumFractionDigits: FORMAT_CURRENCY_DECIMALS[currency],
  }).format(value);
  return formattedSymbol + formattedValue;
};

export const fromBase = (value: BigNumberish, units: number | string = 18): string => {
  return formatUnits(value, units);
};

export const parseAsArray = (input: string) => {
  let result = [];

  try {
    const parsed = JSON.parse(input);
    if (parsed && Array.isArray(parsed)) {
      result = parsed as any;
    }
  } catch (e) {
    console.error('Failed to parse input as array');
  }

  return result;
};

export const mergeState = (source: any, target: any) => {
  if (!source || !target) {
    return target;
  }

  let shouldMerge = false;
  Object.keys(source).forEach(key => {
    if (shouldMerge) {
      return;
    }
    shouldMerge = source[key] !== target[key];
  });

  return shouldMerge ? { ...source, ...target } : source;
};

export const addAlphaToHexColor = (color: string, alpha: number) => {
  if (color.length === 4) {
    color = color.replace(/#([0-9a-fA-F])([0-9a-fA-F])([0-9a-fA-F])/, '#$1$1$2$2$3$3');
  }

  let alphaHex = Math.round((alpha / 100) * 255).toString(16);

  // make alphaHex two digits
  if (alphaHex.length === 1) {
    alphaHex = '0' + alphaHex;
  }

  return color + alphaHex;
};

export const hasPermission = (requiredPermission: string, permissions: string[]) => {
  return permissions.includes(requiredPermission);
};

export const hasPermissions = (requiredPermissions: string[], permissions: string[]) => {
  return requiredPermissions.every(p => hasPermission(p, permissions));
};

export const uuidv4 = (): string => {
  let d = new Date().getTime();
  let d2 = (typeof performance !== 'undefined' && performance.now && performance.now() * 1000) || 0;
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    let r = Math.random() * 16;
    if (d > 0) {
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
};

export const openExternalWindow = (url: string) => {
  return url && window.open(url)
};

function dateDiffInDays(a: Date, b: Date): number {
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
  return Math.floor((utc2 - utc1) / MS_PER_DAY);
}

function dateDiffInMonths(a: Date, b: Date): number {
  return (b.getFullYear() - a.getFullYear()) * 12 + (b.getMonth() - a.getMonth());
}

function dateDiffInYears(a: Date, b: Date): number {
  return b.getFullYear() - a.getFullYear();
}

export const getTimeLeft = (dateString?: string): string => {
  if ( dateString === undefined) return '';

  const givenDate = new Date(dateString);
  const today = new Date();

  if (givenDate < today) {
    return 'Expired';
  }

  const daysLeft = dateDiffInDays(today, givenDate);
  const monthsLeft = dateDiffInMonths(today, givenDate);
  const yearsLeft = dateDiffInYears(today, givenDate);

  if (yearsLeft > 0) {
    return `${yearsLeft} year${yearsLeft > 1 ? 's' : ''}`;
  } else if (monthsLeft > 0) {
    return `${monthsLeft} month${monthsLeft > 1 ? 's' : ''}`;
  } else {
    return `${daysLeft} day${daysLeft > 1 ? 's' : ''}`;
  }
}

export const getTimeStatus = (startDateString?: string, endDateString?: string): string => {
  if (startDateString === undefined) return 'Inactive';
  const startDate = new Date(startDateString);
  const endDate = endDateString ? new Date(endDateString) : undefined;
  const now = new Date();

  return (now >= startDate && (endDate === undefined || now <= endDate)) ? 'Active' : 'Inactive';
};


export const isValidUUID = (id: string): boolean => {
  const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  return uuidRegex.test(id);
};
