import styled from '@emotion/styled';
import { Box } from '@mui/system';
import { ReactComponent as LogoSvg } from 'img/phosphor-logo-white.svg';

const HeaderText = styled.div`
  color: 'white';
  font-size: 32px;
  font-weight: normal;
  height: 56px;
  display: flex;
`;

const Logo = styled(({ fillColor, spinLogo, ...rest }) => <LogoSvg {...rest} />)`
  ${props =>
    props.spinLogo &&
    `animation: spin 8s linear infinite;

  @-moz-keyframes spin {
    100% {
      -moz-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }`}

  > path {
    fill: ${props => props.fillColor ?? 'white'};
  }

  height: 56px;
  width: auto; // Maintain aspect ratio
`;

export interface OrganizationLogoProps {
  spinLogo?: boolean;
  showLogo?: boolean;
  showText?: boolean;
  fillColor?: string;
  textColor?: string;
  logoSize?: string;
  textWidth?: string;
  stacked?: boolean;
}

const OrganizationLogo = ({
  spinLogo,
  showLogo = true,
  showText,
  fillColor,
  textColor,
  logoSize,
  textWidth,
  stacked,
  ...rest
}: OrganizationLogoProps) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      {...rest}
      flexDirection={stacked ? 'column' : 'row'}
      height="56px"
    >
      {showLogo && (
        <Logo spinLogo={spinLogo ?? false} fillColor={fillColor ?? 'white'} height="56px" />
      )}

      {showText && (
        <Box
          sx={{
            ml: stacked ? '0' : '0',
            mt: stacked ? '15px' : '0',
            display: 'flex',
            alignItems: 'center',
            height: '56px',
          }}
        >
          <HeaderText>Platform UI</HeaderText>
        </Box>
      )}
    </Box>
  );
};

export default OrganizationLogo;
