import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { OrganizationLogo } from 'atoms';
import { theme } from 'common/themes/default';
import Page from './Page';

const RouteLoaderPage = () => {
  return (
    <Page height="100vh" width="100vw" background={theme.palette.background.default}>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <CircularProgress disableShrink size={55} sx={{ mb: 2 }} />

        <OrganizationLogo
          showLogo={false}
          showText
          stacked
          fillColor={theme.palette.primary.main}
          textColor={theme.palette.primary.main}
        ></OrganizationLogo>
      </Box>
    </Page>
  );
};

export default RouteLoaderPage;
